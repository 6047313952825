import { withStyles, Grid, Divider } from '@material-ui/core';
import React, { useEffect } from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../../../claim';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import { Nullable } from '../../../../../shared-types';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { RulesResult } from '../rules-reducer';
import { ResultsExceptionsView } from './results-exceptions';
import { ResultsRulesView } from './results-rules';

interface Props extends StyledComponent {
    results: Nullable<RulesResult[]>;
    openAddModal?: any;
    claimType?: any;
}

const rulesResultsView = ({ results, openAddModal, claimType }: Props) => {
    let notifier = '';
    let isTriangleVisible = false;
    if (results != null && results[0] && results[0].rules && results[0].rules[0]) {
        for (const rule of results[0].rules) {
            if (rule.override) {
                notifier = '';
            } else if (rule.override === null && rule.rating === 'Reject') {
                isTriangleVisible = false;
                continue;
            } else {
                isTriangleVisible = true;
                break;
            }
        }
        if (isTriangleVisible) {
            notifier = 'Warning';
        }
    }

    return (
        <ExpansionPanel name="Results" label="Results (CIRE)" notifier={notifier}>
            <React.Fragment>
                {results &&
                    results.map((result, index) => {
                        const panelName = `Policy ${index + 1} - ${result.contractNumber}`;
                        return (
                            <>
                                <ExpansionPanel
                                    name={panelName}
                                    label={panelName}
                                    key={`result-${result.contractNumber}`}
                                    style={{ marginLeft: -24, marginRight: -24 }}
                                >
                                    <ResultsExceptionsView exceptions={result.exceptions} />
                                    <ResultsRulesView rules={result.rules} />
                                    <Grid item xs={12}>
                                        <Divider
                                            style={{ marginTop: '16px', marginBottom: '16px' }}
                                        />
                                    </Grid>
                                </ExpansionPanel>
                            </>
                        );
                    })}
            </React.Fragment>
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    results: getClaim(state).results,
    claimType: getClaim(state).claimType,
});

const mapDispatchToProps = {};

export const RulesResultsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(rulesResultsView));
