export const BenefitCategory = [
    { value: 'Anaemia', label: 'Anaemia' },
    { value: 'Anorectal disease', label: 'Anorectal disease' },
    { value: 'Aphasia/dysphasia', label: 'Aphasia/dysphasia' },
    { value: 'Arrhythmias', label: 'Arrhythmias' },
    { value: 'Biliary tract', label: 'Biliary tract' },
    { value: 'Bladder', label: 'Bladder' },
    { value: 'Bipolar disorder', label: 'Bipolar disorder' },
    { value: 'Bereavement', label: 'Bereavement' },
    { value: 'Bones–other ', label: 'Bones–other' },
    { value: 'Bones–upper limb', label: 'Bones–upper limb' },
    { value: 'Bones–lower limb', label: 'Bones–lower limb' },
    { value: 'Cancer', label: 'Cancer' },
    { value: 'Cancer and leukaemia', label: 'Cancer and leukaemia' },
    { value: 'Cardiomyopathy', label: 'Cardiomyopathy' },
    { value: 'Cardiovascular system', label: 'Cardiovascular system' },
    { value: 'Clotting disorders', label: 'Clotting disorders' },
    { value: 'Coronary artery disease', label: 'Coronary artery disease' },
    {
        value: 'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves)',
        label: 'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves)',
    },
    { value: 'Cranial nerve V (trigeminal nerve)', label: 'Cranial nerve V (trigeminal nerve)' },
    { value: 'Cranial nerve VII (facial nerve)', label: 'Cranial nerve VII (facial nerve)' },
    {
        value: 'Cranial nerve VIII (vestibulocochlear nerve)',
        label: 'Cranial nerve VIII (vestibulocochlear nerve)',
    },
    { value: 'Depression/anxiety', label: 'Depression/anxiety' },
    { value: 'Diplegia / paraplegia', label: 'Diplegia / paraplegia' },
    { value: 'Diseases of the aorta', label: 'Diseases of the aorta' },
    { value: 'Diseases of the pulmonary arteries', label: 'Diseases of the pulmonary arteries' },
    { value: 'Episodic loss of consciousness', label: 'Episodic loss of consciousness' },
    { value: 'Face', label: 'Face' },
    { value: 'Gait disorders/motor coordination', label: 'Gait disorders/motor coordination' },
    { value: 'Hearing', label: 'Hearing' },
    { value: 'Heart transplant', label: 'Heart transplant' },
    { value: 'Hemiplegia', label: 'Hemiplegia' },
    { value: 'Hernia', label: 'Hernia' },
    { value: 'Hypertensive cardiovascular disease', label: 'Hypertensive cardiovascular disease' },
    { value: 'Impairment of consciousness', label: 'Impairment of consciousness' },
    { value: 'Inability to work', label: 'Inability to work' },
    { value: 'Liver disease', label: 'Liver disease' },
    { value: 'Joints–upper limb', label: 'Joints–upper limb' },
    { value: 'Joints–lower limb', label: 'Joints–lower limb' },
    { value: 'Lower gastrointestinal tract', label: 'Lower gastrointestinal tract' },
    { value: 'Lumbar, thoracic and cervical spine', label: 'Lumbar, thoracic and cervical spine' },
    { value: 'Muscles', label: 'Muscles' },
    {
        value: 'Neurological impairment of respiration',
        label: 'Neurological impairment of respiration',
    },
    {
        value: 'Organic brain disorders and dementia',
        label: 'Organic brain disorders and dementia',
    },
    { value: 'Paralysis', label: 'Paralysis' },
    { value: 'Pericardial disease', label: 'Pericardial disease' },
    { value: 'Peripheral arterial disease', label: 'Peripheral arterial disease' },
    { value: 'Peripheral venous disease', label: 'Peripheral venous disease' },
    { value: 'PTSD', label: 'PTSD' },
    { value: 'Quadriplegia', label: 'Quadriplegia' },
    {
        value: 'Schizophrenia/other pyschotic disorders',
        label: 'Schizophrenia/other pyschotic disorders',
    },
    { value: 'Sleep disorders', label: 'Sleep disorders' },
    { value: 'Stroke (cerebrovascular incident)', label: 'Stroke (cerebrovascular incident)' },
    { value: 'The endocrine system', label: 'The endocrine system' },
    { value: 'The limbs', label: 'The limbs' },
    { value: 'The respiratory system', label: 'The respiratory system' },
    { value: 'The skin', label: 'The skin' },
    { value: 'The visual system', label: 'The visual system' },
    { value: 'Upper air passages', label: 'Upper air passages' },
    { value: 'Upper gastrointestinal tract', label: 'Upper gastrointestinal tract' },
    { value: 'Upper urinary tract', label: 'Upper urinary tract' },
    { value: 'Urethra', label: 'Urethra' },
    { value: 'Valvular heart disease', label: 'Valvular heart disease' },
    { value: 'Vestibular disorders', label: 'Vestibular disorders' },
    { value: 'Voice/speech impairment', label: 'Voice/speech impairment' },
    { value: 'White blood cells', label: 'White blood cells' },
    { value: 'Other', label: 'Other' },
];

export const BenefitCategoryDread = [
    { value: 'Accidental HIV', label: 'Accidental HIV' },
    { value: 'AIDS', label: 'AIDS' },
    {
        value: `Alzheimer's disease and progressive dementia`,
        label: `Alzheimer's disease and progressive dementia`,
    },
    { value: 'Aneurysm', label: 'Aneurysm' },
    {
        value: 'Aplastic anaemia and other blood disorders',
        label: 'Aplastic anaemia and other blood disorders',
    },
    { value: 'Arrhythmia', label: 'Arrhythmia' },
    { value: 'Bipolar disorder', label: 'Bipolar disorder' },
    { value: 'Bereavement', label: 'Bereavement' },
    { value: 'Bones–other ', label: 'Bones–other' },
    { value: 'Bones–upper limb', label: 'Bones–upper limb' },
    { value: 'Bones–lower limb', label: 'Bones–lower limb' },
    { value: 'Cancer and leukaemia', label: 'Cancer and leukaemia' },
    { value: 'Cardiomyopathy', label: 'Cardiomyopathy' },
    { value: 'Cardio-pulmonary dysfunction', label: 'Cardio-pulmonary dysfunction' },
    { value: 'Carotid arterial disease', label: 'Carotid arterial disease' },
    { value: 'Cerebrovascular incident (Stoke)', label: 'Cerebrovascular incident (Stoke)' },
    { value: 'Chronic liver disorders', label: 'Chronic liver disorders' },
    { value: 'Coma', label: 'Coma' },
    { value: 'Coronary artery disease', label: 'Coronary artery disease' },
    {
        value: 'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves)',
        label: 'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves)',
    },
    { value: 'Cranial nerve V (trigeminal nerve)', label: 'Cranial nerve V (trigeminal nerve)' },
    { value: 'Cranial nerve VII (facial nerve)', label: 'Cranial nerve VII (facial nerve)' },
    { value: 'Depression/anxiety', label: 'Depression/anxiety' },
    { value: 'Ear, nose and throat disorders', label: 'Ear, nose and throat disorders' },
    { value: 'Endocrine conditions', label: 'Endocrine conditions' },
    { value: 'Eye disorders', label: 'Eye disorders' },
    { value: 'Gastrointestinal disease', label: 'Gastrointestinal disease' },
    { value: 'Heart transplant', label: 'Heart transplant' },
    { value: 'Hernia', label: 'Hernia' },
    { value: 'Hypertensive cardiovascular diseas', label: 'Hypertensive cardiovascular diseas' },
    { value: 'Living Lifestyle catch-all', label: 'Living Lifestyle catch-all' },
    { value: 'Loss of and loss of use of limbs', label: 'Loss of and loss of use of limbs' },
    { value: 'Lumbar, thoracic and cervical spine', label: 'Lumbar, thoracic and cervical spine' },
    { value: 'Major burns', label: 'Major burns' },
    { value: 'Motor neuron disease', label: 'Motor neuron disease' },
    { value: 'Multiple sclerosis', label: 'Multiple sclerosis' },
    { value: 'Muscular dystrophy', label: 'Muscular dystrophy' },
    { value: 'Muscles', label: 'Muscles' },
    { value: 'Myasthenia gravis', label: 'Myasthenia gravis' },
    {
        value: 'Myocardial infarction (heart attack)',
        label: 'Myocardial infarction (heart attack)',
    },
    { value: 'Other autoimmune disorders', label: 'Other autoimmune disorders' },
    {
        value: 'Other central nervous system disorders',
        label: 'Other central nervous system disorders',
    },
    { value: 'Pancreatic disorders', label: 'Pancreatic disorders' },
    { value: 'Paralysis', label: 'Paralysis' },
    { value: `Parkinson's disease`, label: `Parkinson's disease` },
    { value: 'Pericardial disease', label: 'Pericardial disease' },
    { value: 'Peripheral arterial disease', label: 'Peripheral arterial disease' },
    { value: 'PTSD', label: 'PTSD' },
    { value: 'Renal disorders', label: 'Renal disorders' },
    { value: 'Respiratory disorders', label: 'Respiratory disorders' },
    { value: 'Rheumatoid arthritis', label: 'Rheumatoid arthritis' },
    { value: 'Speech disorders', label: 'Speech disorders' },
    { value: 'Sleep disorders', label: 'Sleep disorders' },
    {
        value: 'Schizophrenia/other psychotic disorders',
        label: 'Schizophrenia/other psychotic disorders',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma',
    },
    { value: 'Upper air passages', label: 'Upper air passages' },
    { value: 'Urogenital disorders', label: 'Urogenital disorders' },
    {
        value: 'Valvular and septal diseases of the heart',
        label: 'Valvular and septal diseases of the heart',
    },
    { value: 'Inability to work', label: 'Inability to work' },
    { value: 'Other', label: 'Other' },
];
