import { Button, Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { AddCircle, Cached, CheckCircle, Warning } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { v1 as uuid } from 'uuid';
import { AuthState } from '../../../../auth/auth-reducer';
import { OpenDialog, openDialogAction } from '../../../../confirmation-dialog';
import { getDisabilityDetails } from '../../../../disability-details/reducer';
import { OpenAddModal, openAddModalAction } from '../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../forms';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    getClaimDetails,
    noCauseOfClaimDetails,
} from '../../../synopsis/common/claim-details/claim-details-reducer';
import { RaiseLiability, raiseLiabilityAction } from '../../actions/raise-liability';
import { BenefitAssessmentDetails } from '../../assessment-details-reducer';
import { AssessmentDecisionView } from './assessment-decision';
import { AddAssessmentDecisionProps } from './assessment-decision-add';
import { BenefitAssessmentSummary } from './benefit-assessment-summary';
import { LiabilityStatusView } from './liability-status-view';
import { getLifeAssuredDetailsFromClaim } from '../../../../life-assured-details/reducer';
import { ClaimType } from '../../../../claim/claim-types';
import { Nullable } from '../../../../shared-types';
import { RulesResult } from '../../../synopsis/common/rules/rules-reducer';

interface OwnProps {
    assessmentDecisionAddView:
        | React.FunctionComponent<AddAssessmentDecisionProps>
        | React.ComponentClass<AddAssessmentDecisionProps>;
    automaticBenefitIncrease?: number;
    benefitAssessmentDetails: BenefitAssessmentDetails;
    benefitIndex: number;
    policyIndex: number;
    policyNumber: string;
}

interface StateProps {
    causeOfClaim?: string;
    currentUser: AuthState;
    dateOfEvent?: Date;
    isReadOnly: boolean;
    lastDayAtWork?: Date;
    returnToWork?: Date;
    noCauseOfClaimDetails: boolean;
    registry?: string;
    notification?: any;
    claimType?: Nullable<ClaimType>;
    claim?: Nullable<RulesResult[]>;
}

interface DispatchProps {
    openAddModal: OpenAddModal;
    raiseLiability: RaiseLiability;
    openDialog: OpenDialog;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const benefitAssessmentDetailsView: React.FunctionComponent<Props> = ({
    assessmentDecisionAddView: AssessmentDecisionAddView,
    automaticBenefitIncrease,
    benefitAssessmentDetails,
    classes,
    currentUser,
    isReadOnly,
    openAddModal,
    policyNumber,
    raiseLiability,
    openDialog,
    noCauseOfClaimDetails,
    registry,
    claim,
    notification,
    claimType,
    ...rest
}: Props) => {
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">
                        Benefit - {benefitAssessmentDetails.benefitName}
                    </Typography>
                </Grid>
                {isDreadDisability() && (
                    <Grid container item xs={12} justify="flex-start">
                        <Typography style={{ color: '#8b92a7' }}>
                            Please ensure that you capture the life registry details before adding a
                            decision
                        </Typography>
                        <Typography
                            style={{
                                marginLeft: '5px',
                                fontWeight: 'bold',
                            }}
                        >
                            {isRegistry || ''}
                        </Typography>
                        {checkRegistryEventCaptured(registry === 'Yes' && notification !== null)}
                    </Grid>
                )}
                {isDreadDisability() && (
                    <Grid container item xs={12} justify="flex-start">
                        <Typography style={{ color: '#8b92a7' }}>
                            Please ensure that you capture the claim event details before adding a
                            decision
                        </Typography>
                        <Typography
                            style={{
                                marginLeft: '5px',
                                fontWeight: 'bold',
                            }}
                        >
                            {!noCauseOfClaimDetails || ''}
                        </Typography>
                        {checkRegistryEventCaptured(!noCauseOfClaimDetails)}
                    </Grid>
                )}

                <Grid item xs={10}>
                    <LiabilityStatusView
                        claimLiability={benefitAssessmentDetails.claimLiability}
                        isCireRan={CireClicked()}
                        isOverriden={isDisabled()}
                    />
                </Grid>
            </Grid>

            <Paper className={classes.paper}>
                <BenefitAssessmentSummary
                    {...{ ...rest, benefitAssessmentDetails, currentUser, index: 0 }}
                />
                <Divider />
                <BenefitAssessmentSummary
                    {...{ ...rest, benefitAssessmentDetails, currentUser, index: 1 }}
                />
                <Divider />
                <Grid container>
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{ marginTop: 10 }}>
                            Decision
                        </Typography>
                    </Grid>
                    <ButtonWrapper>
                        <Grid item xs={2}>
                            <Button
                                id={'Add_' + benefitAssessmentDetails.benefitName}
                                color="secondary"
                                style={{ padding: 10, textTransform: 'none' }}
                                onClick={addDecision}
                                disabled={isRegistryEventCaptured()}
                            >
                                <AddCircle style={{ marginRight: 4 }} />
                                Add decision
                            </Button>
                        </Grid>
                    </ButtonWrapper>
                </Grid>
                {switchOn() && showRaiseLiability() && !isReadOnly && (
                    <Grid container>
                        <Grid item xs={10}>
                            <LiabilityStatusView
                                claimLiability={benefitAssessmentDetails.claimLiability}
                            />
                        </Grid>
                        <ButtonWrapper>
                            <Grid container item xs={2} style={{ marginTop: '-15px' }}>
                                <Button
                                    id="Raise-liability"
                                    color="secondary"
                                    style={{ padding: 10, textTransform: 'none' }}
                                    onClick={liability}
                                >
                                    <Cached style={{ marginRight: 4 }} />
                                    Raise liability
                                </Button>
                            </Grid>
                        </ButtonWrapper>
                    </Grid>
                )}
                <BenefitDecisions
                    classes={classes}
                    benefitAssessmentDetails={benefitAssessmentDetails}
                />
            </Paper>
        </React.Fragment>
    );

    function isDreadDisability() {
        return claimType === ClaimType.Dread || claimType === ClaimType.Disability;
    }

    function CireClicked() {
        if (claim && claim[0].isCireRan) {
            return true;
        }
        return false;
    }

    function isDisabled() {
        if (claim && claim[0].isCireRan) {
            let isDisabled = false;
            for (const rule of claim[0].rules) {
                if (rule.comment !== null || rule.rating === 'Reject') {
                    isDisabled = false;
                    continue;
                } else {
                    isDisabled = true;
                    break;
                }
            }
            return isDisabled;
        }
        return true;
    }

    function isRegistryEventCaptured() {
        if (isDreadDisability()) {
            return (
                registry !== 'Yes' || noCauseOfClaimDetails || notification === null || isDisabled()
            );
        } else {
            return false;
        }
    }

    function isRegistry(registry: string) {
        if (registry === 'Yes') {
            return true;
        } else {
            return false;
        }
    }

    function checkRegistryEventCaptured(registryStatus?: boolean) {
        if (registryStatus) {
            return <CheckCircle style={{ color: '#36b37e', marginLeft: '8px' }} />;
        } else {
            return <Warning style={{ color: '#ff6c35', marginLeft: '8px' }} />;
        }
    }
    function addDecision() {
        if (noCauseOfClaimDetails && !isDreadDisability()) {
            openDialog({
                content: {
                    title: 'Error adding decision',
                    body:
                        'Please capture claim catergory, claim condition, detailed claim condition, etc. in the claim event details section before adding a decision',
                },
            });

            return;
        }

        openAddModal({
            modalContent: (
                <AssessmentDecisionAddView
                    {...rest}
                    {...benefitAssessmentDetails}
                    assessmentDescription={getAssessmentDescription()}
                    automaticBenefitIncrease={automaticBenefitIncrease}
                    blankRecord={{
                        assessmentDate: new Date(),
                        assessmentType: getAssessmentDescription(),
                        assessor: currentUser.name,
                        benefitCode: benefitAssessmentDetails.benefitCode,
                        benefitName: benefitAssessmentDetails.benefitName,
                        claimType: undefined,
                        final: true,
                        id: uuid(),
                        policyNumber: policyNumber,
                    }}
                    noPartialPayment={shouldDisableAmountPayable()}
                    policyNumber={policyNumber}
                />
            ),
            modalData: {},
        });
    }

    function switchOn() {
        return false;
    }

    function getAssessmentDescription() {
        if (
            decisionsPresent(benefitAssessmentDetails) &&
            benefitAssessmentDetails.decisions.filter(d => !!d.final).length > 0
        ) {
            return 'Reassessment';
        }
        return 'Assessment';
    }

    function liability() {
        raiseLiability({
            policyNumber: policyNumber,
            benefitCode: benefitAssessmentDetails.benefitCode,
        });
    }

    function showRaiseLiability() {
        return (
            policyNumber &&
            benefitAssessmentDetails &&
            benefitAssessmentDetails.benefitCode &&
            benefitAssessmentDetails.decisions.findIndex(d => d.final === true) !== -1 &&
            !(
                benefitAssessmentDetails.claimLiability &&
                benefitAssessmentDetails.claimLiability.status === 'Successful' &&
                benefitAssessmentDetails.decisions.some(
                    d =>
                        d.final === true &&
                        d.id ===
                            (benefitAssessmentDetails.claimLiability &&
                                benefitAssessmentDetails.claimLiability.id) &&
                        benefitAssessmentDetails.decisions.some(
                            d => d.assessmentType === 'Reassessment'
                        ) &&
                        d.assessmentType === 'Reassessment'
                )
            )
        );
    }

    function shouldDisableAmountPayable() {
        return ['20', '21', '52', '53', '54', '58', '75', '77', '78', '111', '112'].includes(
            benefitAssessmentDetails.benefitCode
        );
    }
};

interface BenefitDecisionsProps extends StyledComponent {
    benefitAssessmentDetails: BenefitAssessmentDetails;
    automaticBenefitIncrease?: number;
}
const BenefitDecisions: React.FunctionComponent<BenefitDecisionsProps> = ({
    automaticBenefitIncrease,
    benefitAssessmentDetails,
    classes,
    ...rest
}: BenefitDecisionsProps) => {
    if (!decisionsPresent(benefitAssessmentDetails)) {
        return null;
    }

    let finalDecision = false;
    let isReassessment = false;

    return (
        <React.Fragment>
            {benefitAssessmentDetails.decisions.map((assessmentDecision, assessmentIndex) => {
                if (finalDecision) {
                    isReassessment = true;
                }
                if (!!assessmentDecision.final) {
                    finalDecision = true;
                }
                return (
                    <React.Fragment key={assessmentDecision.id}>
                        <AssessmentDecisionView
                            {...rest}
                            benefitName={benefitAssessmentDetails.benefitName}
                            sumAssured={benefitAssessmentDetails.sumAssured}
                            assessmentDecision={assessmentDecision}
                            reassessment={isReassessment}
                            automaticBenefitIncrease={automaticBenefitIncrease}
                            commencementDate={benefitAssessmentDetails.commencementDate}
                        />
                        {assessmentIndex !== benefitAssessmentDetails.decisions.length - 1 && (
                            <Divider className={classes.topAndBottomMargin} />
                        )}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

function decisionsPresent(benefitAssessmentDetails: BenefitAssessmentDetails) {
    return benefitAssessmentDetails.decisions && benefitAssessmentDetails.decisions.length > 0;
}

const mapStateToProps = (state: State) => ({
    causeOfClaim: getClaimDetails(state).currentCauseOfClaim,
    currentUser: state.auth as AuthState,
    dateOfEvent: getClaimDetails(state).dateOfEvent,
    isReadOnly: state.claimSession.readOnly,
    lastDayAtWork: getDisabilityDetails(state).lastDayAtWork,
    noCauseOfClaimDetails: noCauseOfClaimDetails(state),
    returnToWork: getDisabilityDetails(state).returnToWork,
    notification: getLifeAssuredDetailsFromClaim(state.claimSession.claim).claimLifeNotifications,
    registry: getLifeAssuredDetailsFromClaim(state.claimSession.claim)
        .claimLifeNotificationsUpdated,
    claimType: state.claimSession.claim.claimType,
    claim: state.claimSession.claim.results,
});

const mapDispatchToProps = {
    openAddModal: openAddModalAction,
    raiseLiability: raiseLiabilityAction,
    openDialog: openDialogAction,
};

export const BenefitAssessmentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(benefitAssessmentDetailsView));
